import homePageScreen7 from '@/assets/messageDemo/homePageScreen7.png';
import homePageScreen10 from '@/assets/messageDemo/homePageScreen10.png';
import homePageScreen1 from '@/assets/messageDemo/homePageScreen1.png';
import homePageScreen12 from '@/assets/messageDemo/homePageScreen12.png';
import homePageScreen3 from '@/assets/messageDemo/homePageScreen3.png';
import homePageScreen8 from '@/assets/messageDemo/homePageScreen8.png';
import homePageScreen4 from '@/assets/messageDemo/homePageScreen4.png';
import homePageScreen14 from '@/components/homePage/img/dashIns.png';
import homePageScreen15 from '@/components/homePage/img/dashWhatsapp.png';
import homePageScreen2 from '@/assets/messageDemo/homePageScreen2.png';
import homePageScreen13 from '@/assets/messageDemo/homePageScreen13.png';
import homePageScreen9 from '@/assets/messageDemo/homePageScreen9.png';
import homePageScreen11 from '@/assets/messageDemo/homePageScreen11.png';
import homePageScreen5 from '@/assets/messageDemo/homePageScreen5.png';
import homePageScreen6 from '@/assets/messageDemo/homePageScreen6.png';
export const jsonDataScreenUsage = {
  code: 200,
  message: 'OK',
  data: {
    app_usages: [
      {
        package_name: '99e6a4af-7dcb-4cb4-9bca-b0b566262be5',
        name: 'Spotify',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/14.png',
        usage_time: 21600,
        frequency: 18,
      },
      {
        package_name: 'cf6405f9-2715-400f-8740-7deeee86e2e8',
        name: 'YouTube',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/08.png',
        usage_time: 18900,
        frequency: 20,
      },
      {
        package_name: 'abab805e-ca8e-424b-831e-476c9f258441',
        name: 'Netflix',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/07.png',
        usage_time: 16200,
        frequency: 5,
      },
      {
        package_name: 'e03bfa96-273a-4625-ab65-111024b0af0a',
        name: 'Pokémon GO',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/13.png',
        usage_time: 15600,
        frequency: 14,
      },
      {
        package_name: '220aaf07-78dd-41c3-92ac-37d8f76f56c5',
        name: 'X',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/05.png',
        usage_time: 15600,
        frequency: 2,
      },
      {
        package_name: '480065ce-9a1f-4c94-b28a-3bc80000296c',
        name: 'WhatsApp',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/06.png',
        usage_time: 13500,
        frequency: 15,
      },
      {
        package_name: '8c9bff15-02cf-4a6f-8ea4-f48835bad055',
        name: 'Candy Crush Soga',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/11.png',
        usage_time: 11400,
        frequency: 9,
      },
      {
        package_name: '50680912-5cd4-4cd8-9257-79934ed5ea40',
        name: 'TikTok',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/03.png',
        usage_time: 10800,
        frequency: 12,
      },
      {
        package_name: 'b9522756-092f-4c69-a3a3-7cb2c591987b',
        name: 'Clash of Clans',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/12.png',
        usage_time: 10200,
        frequency: 4,
      },
      {
        package_name: '32ca90d4-93fd-46e5-b937-0d0737deeebe',
        name: 'Instagram',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/01.png',
        usage_time: 9000,
        frequency: 10,
      },
      {
        package_name: '71536dac-f68e-466d-9658-6d2b3394646b',
        name: 'Reddit',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/10.png',
        usage_time: 7200,
        frequency: 7,
      },
      {
        package_name: '3c3f840a-061f-4b88-bc37-3d4154417760',
        name: 'Facebook',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/02.png',
        usage_time: 6300,
        frequency: 8,
      },
      {
        package_name: 'd40d7c5c-2022-42ae-9941-3c5003b775ba',
        name: 'Duolingo',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/15.png',
        usage_time: 5400,
        frequency: 6,
      },
      {
        package_name: 'b1247f96-61a8-41df-94bc-0aa0f61efb77',
        name: 'Snapchat',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/04.png',
        usage_time: 4500,
        frequency: 6,
      },
      {
        package_name: '62b1c94d-35a6-4be0-a7cc-cff30b7f4129',
        name: 'Pinterest',
        logo_url:
          'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/09.png',
        usage_time: 2700,
        frequency: 3,
      },
    ],
    screen_usage_time: 168900,
    update_time: 1699005142,
  },
};

export const jsonDataCurrTopList = [
  {
    name: 'Spotify',
    data: [21600],
  },
  {
    name: 'YouTube',
    data: [18900],
  },
  {
    name: 'Netflix',
    data: [16200],
  },
  {
    name: 'X',
    data: [15600],
  },
  {
    name: 'Pokémon GO',
    data: [15600],
  },
  {
    name: 'WhatsApp',
    data: [13500],
  },
];

export const jsonDataCurrTop = [
  'Spotify',
  'YouTube',
  'Netflix',
  'X',
  'Pokémon GO',
  'WhatsApp',
];
